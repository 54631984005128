import { persistCredentials } from '@/hooks/useIdentity';
import { IUser } from '@/types';
import { useOAuth2 } from '@tasoskakour/react-use-oauth2';
import Button from '@/ui/Button';
import { Row } from '@/ui/primatives';
import * as Icon from '@/ui/Icon';
import * as Sentry from '@sentry/nextjs';
import { ErrorMessage } from '@/components/Form';
import { BASE_API_URL, BASE_WEBAPP_URL, GIT_HUB_CLIENT_ID } from '@/config/environment';

const onSuccess = (user: IUser, redirect?: string) => {
  persistCredentials(user);
  const win: Window = window;
  if (user.status === 'incomplete') {
    win.location = `/register/complete`;
  } else {
    win.location = redirect || '/';
  }
};

const onFailure = (error: Error) => {
  const win: Window = window;
  Sentry.captureException(error);
  win.location = '/login?error=failed-oauth';
};

export const GithubOauthProvider: React.FC<{ redirect?: string }> = ({ redirect }) => {
  const { data, loading, error, getAuth, logout } = useOAuth2({
    authorizeUrl: 'https://github.com/login/oauth/authorize',
    clientId: GIT_HUB_CLIENT_ID,
    redirectUri: `${BASE_WEBAPP_URL}/oauth`,
    exchangeCodeForTokenHeaders: {
      'X-OAuth-Provider': 'Github',
    },
    scope: 'read:user, user:email',
    responseType: 'code',
    exchangeCodeForTokenServerURL: `${BASE_API_URL}/login`,
    exchangeCodeForTokenMethod: 'POST',
    onSuccess: (payload: any) => onSuccess(payload.user as IUser, redirect),
    onError: (error_: any) => onFailure(error_),
  });

  if (error) {
    return (
      <ErrorMessage>
        We&apos;ve encountered an error, The Oxen team has been notified and is looking into the issue.{' '}
      </ErrorMessage>
    );
  }

  return (
    <Button.Basic
      disabled={loading}
      stretch
      testId="register-submit"
      onClick={() => getAuth()}
      style={{ padding: '10px 30px' }}
    >
      <Row alignItems="center" gap={10}>
        <Icon.SocialGithub size="26" />
        {loading ? <span>Loading...</span> : <span>Continue with GitHub</span>}
      </Row>
    </Button.Basic>
  );
};
