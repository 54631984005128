'use client';

import LoginForm, { FormValues as LoginFormValues } from './LoginForm';
import { useState } from 'react';
import Link from 'next/link';
import { Identity } from '@/hooks/useIdentity';
import LoginDetailsForm, { FormValues as LoginDetailsFormValues } from './LoginDetailsForm';

import * as Styled from './styled';
import { Stack } from '@/ui/primatives';

interface FormProps {
  redirect: string;
  errorMessage?: string;
  loginError?: string;
}

const Login: React.FC<FormProps> = ({ redirect, loginError }) => {
  const [identifier, setIdentifier] = useState('');
  const { login: doLogin, status: loginStatus, error } = Identity.useContainer();

  const navigateToRedirectUrl = () => {
    window.location.href = redirect || '/';
  };

  const continueLogin = async ({ identifier }: LoginFormValues) => {
    setIdentifier(identifier);
  };

  const submitLogin = ({ identifier, password }: LoginDetailsFormValues) => {
    doLogin({
      identifier,
      password,
      onSuccess: navigateToRedirectUrl,
    });
  };

  return (
    <Styled.LoginForm>
      <Stack gap={40}>
        <h4>Log in to Oxen</h4>
        {identifier === '' ? (
          <LoginForm onSubmit={continueLogin} loginError={loginError} redirect={redirect} />
        ) : (
          <LoginDetailsForm
            responseError={error}
            onSubmit={submitLogin}
            defaultValues={{ identifier: identifier || '', password: '' }}
            redirect={redirect}
            isLoading={loginStatus === 'loading'}
          />
        )}
        <div style={{ textAlign: 'center' }}>
          <span>Don&apos;t have an account? </span>
          <Link className="link-underline" href={'/register'}>
            Sign up
          </Link>
        </div>
      </Stack>
    </Styled.LoginForm>
  );
};

export default Login;
