import styled from 'styled-components';
import { colors, breakPoints } from '@/styles';

export const LoginForm = styled.div.attrs((props) => ({}))`
  display: inline-flex;
  width: 470px;
  z-index: 1;
  @media (max-width: ${breakPoints.mobile}) {
    width: auto;
  }

  h4 {
    text-align: center;
    display: block;
  }
`;

export const Seperator = styled.div`
  height: 2px;
  width: 100%;
  background-color: ${colors.primary.black};
`;
